import { RouterStateSerializer } from '@ngrx/router-store';
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { HydrationEffects } from './hydration/hydration.effects';
import { LocationStateModule } from './features/location/+state/location.state.module';
import { SearchStateModule } from './features/search/+state/search.state.module';
import { HomeStateModule } from './features/home/+state/home.state.module';
import { FalLiteStateModule } from './features/fal-lite/+state/falLite.state.module';
import { ErrorHandler, NgModule, ApplicationRef, Injector, Type, DoBootstrap } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './index';
import { CustomSerializer } from './hydration/router/custom-serializer';
import { SharedModule } from './shared/shared.module';
import { APP_BASE_HREF } from '@angular/common';
import { FAL_ROOT_ELEMENT } from './core/util/root-element';
import { getRootElement, getBaseUrl, FalAngularElements } from '../app/core/util/root-element';
import { RouterService } from './shared/services/router/router.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    FalLiteStateModule,
    HomeStateModule,
    SearchStateModule,
    LocationStateModule,
    SharedModule,
    StoreDevtoolsModule.instrument({
      name: 'fal-spa',
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([HydrationEffects]),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    })
  ],
  providers: [
    {
      provide: FAL_ROOT_ELEMENT,
      useFactory: getRootElement
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseUrl,
      deps: [FAL_ROOT_ELEMENT]
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    },
    RouterService
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {
    // intentionally empty
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    if (document.body.querySelector('fal-saas')) {
      appRef.bootstrap(AppComponent);
    }
    FalAngularElements.forEach((e) => this.registerCustomElement(e[1], e[0]));
  }

  /**
   * Registers a Component as a custom element if it's not already registered.
   */
  registerCustomElement(componentType: Type<any>, name: string): void {
    if (!customElements.get(name)) {
      const component = createCustomElement(componentType, { injector: this.injector });
      customElements.define(name, component);
    }
  }
}
