import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { EnvironmentService } from '@commonspirit/ngx-environment';
import { CjaTagService } from '../cja-tag/cja-tag.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  private _previousUrl: string | undefined = undefined;
  public get previousUrl(): string | undefined {
    return this._previousUrl;
  }
  private set previousUrl(url: string | undefined) {
    this._previousUrl = url;
  }

  private _currentUrl: string | undefined = undefined;
  public get currentUrl(): string | undefined {
    return this._currentUrl;
  }
  private set currentUrl(url: string | undefined) {
    this._currentUrl = url;
  }

  constructor(private router: Router, private cjaTagService: CjaTagService, private environmentService: EnvironmentService) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.sendAdobeCJAEvent();
      } else if (event instanceof NavigationStart) {
        this.previousUrl = this.currentUrl;
      }
    });
  }

  private sendAdobeCJAEvent() {
    this.cjaTagService.logCJAEvent({
      event: 'cmp:show',
      event_name: 'find_location:screen view',
      page: {
        URL: window.document.domain + this.router.url, //full URL of content being viewed
        screen: document.title, //dynamic screen name value
        path: this.router.url, //route of content being viewed in pathname format
        section: 'my profile', //either my profile or medical records based on screen.
        system: 'CHI', //dignity-health or CHI
        division: this.environmentService.getSetting('shared.divisioncodes'), //mapped division name, if avail
        facility: this.getCurrentFacilityName(), //facility name, if avail
        medicalGroup: '', //medical group name, if avail
        serviceLine: '', //service line, if avail
        market: this.environmentService.getSetting('shared.marketcodes') //market name, if avail
      },
      site: {
        language: 'en' //en for english
      },
      visitor: {
        loginStatus: 'false', //true or false
        dhomeId: '' //dhome ID if avail
      },
      patientEngagement: 'true'
    });
  }

  public getCurrentFacilityName(): string {
    if (!this.currentUrl) return '';
    const match = this.currentUrl.match(/\/[rtl]\//gi);
    if (!match) return '';
    const facility = this.currentUrl.split(match[0])[1].split('/')[1];
    return facility.fromUrlFriendly();
  }

  public parseUrlToComponent(url: string): string {
    if (url.split('/').join('').length === 0) return '/';

    const match = url.match(/\/[rtl]\//gi);
    if (!match) return '/location';

    return '/search';
  }

  public getCurrentUrlFriendly(): string | undefined {
    return this.currentUrl;
  }

  public getPreviousUrlFriendly(): string | undefined {
    return this.previousUrl;
  }
}
