import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SettingService } from '@commonspirit/ngx-environment';
import { SettingKey } from '../../core/models/setting-key';

export class EntryComponent {
  constructor(public settingService: SettingService, public router: Router) {
    const instrumentationKey = settingService.getValue(SettingKey.AppInsightsInstrumentationKey) as string;
    if (!!instrumentationKey) {
      const angularPlugin = new AngularPlugin();
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKey,
          enableCorsCorrelation: false,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          }
        }
      });
      appInsights.loadAppInsights();
    }
  }
}
