/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@commonspirit/ngx-environment';

@Injectable({
  providedIn: 'root'
})
export class CjaTagService {
  scriptUrl = '';

  constructor(public environmentService: EnvironmentService) {
    this.scriptUrl = this.environmentService.getEndpoint('adobeScriptUrl')?.url as string;
    this.ensureScriptTag(this.scriptUrl);
  }

  public logCJAEvent(data?: any): void {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(data);
  }

  scriptLoaded(scriptUrl: string): boolean {
    return Array.from(document.getElementsByTagName('script')).some((script) => script.src.includes(scriptUrl));
  }

  ensureScriptTag(scriptUrl: string): void {
    if (!scriptUrl || this.scriptLoaded(scriptUrl)) {
      return;
    }
    const bodyElement = document.querySelector('body');
    const scriptTag: HTMLScriptElement = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = `(function(a,b,c,d){
      a='${scriptUrl}';
      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();`;
    bodyElement?.appendChild(scriptTag);
    console.log('The Adobe CJA script has been loaded from spa');
  }
}
